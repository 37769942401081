import { useModelStore } from "@/stores/modelStore";

export function getVatAmount(item){
    let vatAmount = 0;
    if(item.vat){
      vatAmount = Number(item.price) * Number(item.vat.value);
    } else {
      let vat = useModelStore().vats.find((i) => i.id === item.vat_id);
      if(vat) vatAmount = Number(item.price) * Number(vat.value);
    }
    return vatAmount;
}

export function getVatAmountCost(item){
  let vatAmount = 0;
  if(item.vat){
    vatAmount = Number(item.cost_net) * Number(item.vat.amount) / 100;
  } else {
    let vat = useModelStore().vats.find((i) => i.id === item.vat_id);
    if(vat) vatAmount = Number(item.cost_net) * Number(vat.amount) / 100;
  }
  return vatAmount;
}
